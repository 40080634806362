import React from 'react';
import Layouts from 'layouts';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { Applications } from 'screens';

const SportsPage = (props) => {
  const intl = useIntl();
  const { location } = props;

  return (
    <Layouts
      title="ALLTOP ASIA ได้รวบรวมเว็บคาสิโนไว้มากมายเพื่อให้คุณได้ร่วมสนุก!"
      description="และเพลิดเพลินไปกับคาสิโนสดชั้นนำของโลก ที่ถูกคัดสรรมาเพื่อให้คุณได้ร่วมสัมผัส ไม่ว่าจะเป็นคาสิโนชั้นนำจากเอเชียหรือยุโรป เราได้รวบรวมไว้ให้คุณแล้วที่นี่"
      pathname={location.pathname}
    >
      <Applications type="sports" />
    </Layouts>
  );
};

SportsPage.propTypes = {
  location: PropTypes.string.isRequired,
};


export default SportsPage;
